import React from 'react'

class IcelandSlides extends React.Component {
  componentDidMount = () => {
    window.location.href = 'https://docs.google.com/presentation/d/14-CFHUFRd0iL6QrqN_HuyeNuBH27hK23rNzfWvRMkZY/edit?usp=sharing'
  }

  render () {
    return null
  }
}

export default IcelandSlides
